const StarOutline = () => (
  <svg
    width="34"
    height="32"
    viewBox="0 0 34 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8404 1.96233L20.8861 8.04691C21.1846 8.64424 21.7608 9.05844 22.4289 9.15429L29.2422 10.135C30.9256 10.3781 31.5955 12.4182 30.3772 13.5872L25.4503 18.3214C24.9661 18.7869 24.7457 19.4561 24.8603 20.1134L26.023 26.797C26.3093 28.4504 24.5496 29.7118 23.045 28.9296L16.9554 25.7718C16.3584 25.462 15.6434 25.462 15.0446 25.7718L8.95501 28.9296C7.45039 29.7118 5.69066 28.4504 5.97874 26.797L7.13974 20.1134C7.25428 19.4561 7.03388 18.7869 6.5497 18.3214L1.6228 13.5872C0.404525 12.4182 1.0744 10.3781 2.75777 10.135L9.57109 9.15429C10.2392 9.05844 10.8171 8.64424 11.1156 8.04691L14.1596 1.96233C14.9128 0.45787 17.0872 0.45787 17.8404 1.96233Z"
      stroke="#E31337"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const FullStar = () => (
  <svg
    width="34"
    height="32"
    viewBox="0 0 34 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.8635 19.866C26.4318 20.2843 26.2335 20.8893 26.3318 21.4827L27.8135 29.6827C27.9385 30.3777 27.6452 31.081 27.0635 31.4827C26.4935 31.8993 25.7352 31.9493 25.1135 31.616L17.7318 27.766C17.4752 27.6293 17.1902 27.556 16.8985 27.5477H16.4468C16.2902 27.571 16.1368 27.621 15.9968 27.6977L8.61349 31.566C8.24849 31.7493 7.83516 31.8143 7.43016 31.7493C6.44349 31.5627 5.78516 30.6227 5.94682 29.631L7.43016 21.431C7.52849 20.8327 7.33016 20.2243 6.89849 19.7993L0.880158 13.966C0.376824 13.4777 0.201824 12.7443 0.431824 12.0827C0.655158 11.4227 1.22516 10.941 1.91349 10.8327L10.1968 9.63102C10.8268 9.56602 11.3802 9.18268 11.6635 8.61602L15.3135 1.13268C15.4002 0.966016 15.5118 0.812682 15.6468 0.682682L15.7968 0.566016C15.8752 0.479349 15.9652 0.407682 16.0652 0.349349L16.2468 0.282682L16.5302 0.166016H17.2318C17.8585 0.231016 18.4102 0.606016 18.6985 1.16602L22.3968 8.61602C22.6635 9.16102 23.1818 9.53935 23.7802 9.63102L32.0635 10.8327C32.7635 10.9327 33.3485 11.416 33.5802 12.0827C33.7985 12.751 33.6102 13.4843 33.0968 13.966L26.8635 19.866Z"
      fill="#E31337"
    />
  </svg>
);

type StarRatingProps = {
  rating: number;
  onRatingChange: (rating: number) => void;
};

export const StarRating = ({ rating, onRatingChange }: StarRatingProps) => {

  const getFillPercentage = (starValue: number) => {
    if (rating >= starValue) return 100;
    if (rating >= starValue - 0.5) return 50;
    return 0;
  };

  return (
    <div style={{ display: 'flex', gap: 20 }}>
      {[1, 2, 3, 4, 5].map((val) => (
        <div
          key={val}
          style={{
            position: 'relative',
            width: 34,
            height: 32,
          }}
        >
          <StarOutline/>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: `${getFillPercentage(val)}%`,
              overflow: 'hidden',
              height: '100%'
            }}
          >
            <FullStar/>
          </div>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '50%',
              height: '100%',
              zIndex: 1
            }}
            onMouseEnter={() => onRatingChange(val - 0.5)}
            onClick={() => onRatingChange(val - 0.5)}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: '50%',
              width: '50%',
              height: '100%',
              zIndex: 1
            }}
            onMouseEnter={() => onRatingChange(val)}
            onClick={() => onRatingChange(val)}
          />
        </div>
      ))}
    </div>
  );
};
