import React, { useMemo } from 'react';
import { StyledText, StyledWrapper } from './SingInMethodButton.styled';
import { Content } from '../../../Content/Content';
import { TranslationTypesEnum } from '../../../../lib/types/translation-types-enum';
import { GoogleIcon } from '../../../UI/SVG/GoogleIcon';
import { FacebookIcon } from '../../../UI/SVG/FacebookIcon';
import { AppleIcon } from '../../../UI/SVG/AppleIcon';
import { appleProvider, auth, facebookProvider, googleProvider } from '../../../../../firebase';
import { signInWithPopup } from 'firebase/auth';
import { useRouter } from '../../../../lib/hooks/use-router';
import { setCredentialsToCookies } from '../../../../lib/server-side/cookies';
import { toast } from 'react-toastify';
import { NOTIFICATION, SignInProviderEnum, USER_ROLE_PASSCODE } from '@vaza-eu/shoelessly';
import { useSignInUserWithProviderMutation } from '../../../../services/user/use-sign-in-user-with-provider-mutation';
import { useFormContext } from 'react-hook-form';

type SingInMethodButtonProps = {
  type: 'big' | 'small';
  method: 'google' | 'facebook' | 'apple';
  onClose?: () => void;
};

export const SingInMethodButton = ({ type, method, onClose }: SingInMethodButtonProps) => {
  const { push, locale } = useRouter();
  const { mutateAsync: signInUser } = useSignInUserWithProviderMutation();
  const { getValues } = useFormContext();

  const startIcon = useMemo(() => {
    switch (method) {
      case 'google':
        return <GoogleIcon/>;
      case 'facebook':
        return <FacebookIcon/>;
      case 'apple':
        return <AppleIcon/>;
      default:
        return null;
    }
  }, [method]);

  const handleSignIn = async () => {
    const isSpecialist = getValues('isSpecialist');

    if (isSpecialist) {
      const enteredSpecialistPasscode = getValues('specialistPasscode');
      if (
        enteredSpecialistPasscode !== USER_ROLE_PASSCODE.DOCTOR &&
        enteredSpecialistPasscode !== USER_ROLE_PASSCODE.COPYWRITER
      ) {
        toast(NOTIFICATION.INVALID_PASSCODE[locale], { type: 'error' });
        return;
      }
    }

    try {
      let provider;
      let currentAuthProvider;

      switch (method) {
        case 'google':
          provider = googleProvider;
          currentAuthProvider = SignInProviderEnum.GOOGLE;
          break;
        case 'facebook':
          provider = facebookProvider;
          currentAuthProvider = SignInProviderEnum.FACEBOOK;
          break;
        case 'apple':
          provider = appleProvider;
          currentAuthProvider = SignInProviderEnum.APPLE;
          break;
        default:
          throw new Error('Unsupported authentication provider');
      }

      if (!provider) return;

      const result = await signInWithPopup(auth, provider);
      const token = await result.user.getIdToken();
      if (token) setCredentialsToCookies(token);

      await signInUser({
        provider: currentAuthProvider,
        passcode: getValues('specialistPasscode'),
      });

      if (onClose) onClose();
    } catch (error: any) {
      console.error('Error signing in:', error);

      if (error?.code === 'auth/account-exists-with-different-credential') {
        toast(
          NOTIFICATION.SIGN_ERROR_ACCOUNT_REGISTERED_WITH_DIFFERENT_PROVIDER[locale],
          { type: 'error' }
        );
        return;
      }

      toast(NOTIFICATION.ERROR[locale], { type: 'error' });
    }
  };

  return (
    <StyledWrapper {...{ type, startIcon }} onClick={handleSignIn}>
      {type === 'big' && (
        <StyledText>
          <Content id={'singInMethod.' + method} type={TranslationTypesEnum.Main}/>
        </StyledText>
      )}
    </StyledWrapper>
  );
};
