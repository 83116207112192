import styled from 'styled-components';
import { desktopMedia } from '@vaza-eu/vaza/dist/styles/devices';
import { Button } from '../../UI/Button/Button';

export const StyledWrapper = styled.div`
  max-width: 98dvw;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  overflow-y: auto;
  background-color: #f7e9e7;

  @media (${desktopMedia}) {
    width: 1215px;
    max-height: 95dvh;
    height: auto;
  }
`;

export const StyledTitle = styled.h1`
  font-family: Futura Extra Black Condensed;
  font-size: 28px;
  text-align: center;

  @media (${desktopMedia}) {
    font-size: 48px;
    text-align: left;
  }
`;

export const StyledText = styled.p`
  font-family: "Apercu Pro";
`;

export const StyledCountDownWrapper = styled.div`

`;

export const StyledCountDownText = styled.h3`
  font-family: Futura Extra Black Condensed;
`;

export const StyledCountDownCount = styled.h1`
  font-family: Futura Extra Black Condensed;
  text-align: center;
`;

export const StyledConnectButton = styled(Button)`
  font-family: Futura Extra Black Condensed;
  font-size: 18px;
  background-color: #e31337;
  border-radius: 50px;
  box-shadow: 4px 8px 24px 0 rgba(36, 107, 253, 0.25);
  padding: 15px 0;
  transition: background-color 0.3s;
  width: 90%;

  :disabled {
    background-color: #d1d5db;
    color: white;
    cursor: not-allowed;
  }

  @media (${desktopMedia}) {
    width: 350px;
  }
`;

export const StyledUserInfo = styled.div`
  background-color: #ffffff;
  border-radius: 17px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;

  @media (${desktopMedia}) {
    width: 60%;
  }
`;

export const StyledUserInfoImage = styled.div`
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  width: 100px;
  min-width: 100px;
  height: 100px;
  min-height: 100px;

  img {
    object-fit: cover;
  }

  @media (${desktopMedia}) {
    width: 140px;
    height: 140px;
  }
`;

export const StyledUserInfoContents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const StyledUserInfoName = styled.div`
  font-family: Futura Extra Black Condensed;
  font-size: 28px;
`;

export const StyledUserInfoOccupation = styled.div`

`;

export const StyledUserStarts = styled.div`
  font-family: Futura Extra Black Condensed;
  font-size: 20px;
`;

export const StyledUserEnds = styled.div`
  font-family: Futura Extra Black Condensed;
  font-size: 20px;
`;