import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState, } from 'react';
import { StyledButton, StyledButtonGroup } from './AppProvider.styled';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import { EditTranslationModal } from '../../components/Modals/EditTranslationModal/EditTranslationModal';
import { TranslationProps, TranslationTypesEnum, } from '../../lib/types/translation-types-enum';
import { GetCurrentUserResponse, USER_ROLES } from '@vaza-eu/shoelessly';
import { setCredentialsToCookies } from '../../lib/server-side/cookies';
import { onIdTokenChanged } from '@firebase/auth';
import { getAuth } from 'firebase/auth';
import { useGetCurrentUserQuery } from '../../services/user/use-get-current-user-query';
import { CallModal } from '../../components/Modals/CallModal/CallModal';
import { AppointmentReviewModal } from '../../components/Modals/AppointmentReviewModal/AppointmentReviewModal';

type AppContextProps = {
  isEditing: boolean;
  setIsEditTranslationModalOpen: Dispatch<SetStateAction<boolean>>;
  translationProps: TranslationProps;
  setTranslationProps: Dispatch<SetStateAction<TranslationProps>>;
  translationType: TranslationTypesEnum;
  user: GetCurrentUserResponse | null;
  refetchUser: () => Promise<any>;
  isFetchingUser: boolean;
};

const AppContext = createContext<AppContextProps>({
  isEditing: false,
  setIsEditTranslationModalOpen: () => undefined,
  translationProps: {
    key: '',
    type: TranslationTypesEnum.Main,
  },
  setTranslationProps: () => undefined,
  translationType: TranslationTypesEnum.Main,
  user: null,
  refetchUser: () => Promise.resolve(),
  isFetchingUser: false,
});

type AppProviderProps = {
  children: ReactNode | ReactNode[];
  translationType: TranslationTypesEnum;
};

export const AppProvider = ({
  children,
  translationType = TranslationTypesEnum.Main,
}: AppProviderProps) => {
  const { data: user, refetch: refetchUser, isFetching: isFetchingUser } = useGetCurrentUserQuery();

  const [isEditing, setIsEditing] = useState(false);
  const [isEditTranslationModalOpen, setIsEditTranslationModalOpen] = useState(false);
  const [translationProps, setTranslationProps] = useState<TranslationProps>({
    key: '',
    type: translationType,
  });

  const isAllowedToEditText = user?.permissions?.includes(USER_ROLES.EDIT_TEXT);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onIdTokenChanged(auth, async (authUser) => {
      try {
        if (authUser) {
          const token = await authUser.getIdToken();
          setCredentialsToCookies(token);
          console.info('Token refreshed');
          await refetchUser();
        }
      } catch (e) {
        console.error(e);
      }
    });

    return () => unsubscribe();
  }, [refetchUser]);

  const ctx = useMemo(
    () => ({
      isEditing,
      setIsEditTranslationModalOpen,
      translationProps,
      setTranslationProps,
      translationType,
      user,
      refetchUser,
      isFetchingUser,
    }),
    [
      isEditing,
      setIsEditTranslationModalOpen,
      translationProps,
      setTranslationProps,
      translationType,
      user,
      refetchUser,
      isFetchingUser,
    ]
  );

  return (
    <AppContext.Provider value={ctx}>
      {children}
      <StyledButtonGroup>
        {isAllowedToEditText && <StyledButton onClick={() => setIsEditing(!isEditing)}>
          {isEditing ? <EditOffIcon/> : <EditIcon/>}
        </StyledButton>}
      </StyledButtonGroup>
      {isAllowedToEditText && (
        <EditTranslationModal
          isOpen={isEditTranslationModalOpen}
          onClose={() => setIsEditTranslationModalOpen(false)}
        />
      )}
      <CallModal/>
      <AppointmentReviewModal/>
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
