import styled, { css } from 'styled-components';
import { desktopMedia } from '@vaza-eu/vaza/dist/styles/devices';

export const StyledWrapper = styled.div<{
  isFocused: boolean;
  isFilled: boolean;
  disableBottomBorderRadius?: boolean;
  isError: boolean;
}>`
  background-color: white;
  height: 60px;
  border-radius: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  gap: 15px;
  position: relative;

  * {
    transition: fill 0.2s;
  }

  ${({ isFocused }) =>
  isFocused &&
  css`
      * {
        fill: #6f6cc9;
      }
    `};

  ${({ isFilled }) =>
  isFilled &&
  css`
      * {
        fill: #000;
      }
    `};

  ${({ disableBottomBorderRadius }) =>
  disableBottomBorderRadius &&
  css`
      border-radius: 16px 16px 0 0;
    `};

  ${({ isError, isFocused }) =>
  isError &&
  !isFocused &&
  css`
      outline: 2px solid #ff0000;
    `};

  @media (${desktopMedia}) {
    width: 378px;
  }
`;

export const StyledPlaceholder = styled.label<{
  hasStartIcon: boolean;
  fieldName?: string;
}>`
  font-family: "Apercu Pro";
  color: #9e9e9e !important;
  font-size: 14px;
  position: absolute;
  left: 52px;
  cursor: text;
  user-select: none;

  * {
    color: #9e9e9e !important;
  }

  ${({ hasStartIcon }) =>
  !hasStartIcon &&
  css`
      left: 20px;
    `};

  ${({ fieldName }) =>
  fieldName === 'phoneNumber' &&
  css`
      left: 52px;
    `};
`;

export const StyledStartIcon = styled.div``;

export const StyledInput = styled.input`
  font-family: "Apercu Pro";
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  color: #000;
  font-size: 16px;
  font-weight: 700;

  :focus {
    outline: none;
  }
`;

export const StyledEndIcon = styled.div`
  cursor: pointer;
`;

export const StyledOptionsWrapper = styled.div`
  max-height: 200px;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: absolute;
  top: 100%;
  z-index: 2;
  left: 0;
  right: 0;
  border-radius: 0 0 16px 16px;
  padding: 10px 0;

  :focus {
    outline: none;
  }
`;

export const StyledOption = styled.button`
  font-family: "Apercu Pro";
  border: none;
  outline: none;
  text-align: left;
  background-color: transparent;
  width: 100%;
  height: 100%;
  color: #000;
  font-size: 17px;
  font-weight: 700;
  padding: 10px 20px;

  :focus {
    outline: none;
  }
  
  :hover {
    background-color: #f5f5f5;
  }
`;
