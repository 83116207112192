import styled from 'styled-components';
import { Button } from '../../UI/Button/Button';
import { desktopMedia } from '@vaza-eu/vaza/dist/styles/devices';

export const StyledWrapper = styled.div`
  max-width: 98dvw;
  padding: 20px 14px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
  background-color: #f7e9e7;

  @media (${desktopMedia}) {
    max-width: 1215px;
    gap: 20px;
    padding: 30px;
  }
`;

export const StyledTopPanel = styled.h1`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  @media (${desktopMedia}) {
    flex-direction: row;
    gap: 50px;
  }
`;

export const StyledTitle = styled.h1`
  font-family: Futura Extra Black Condensed;
  font-size: 48px;
  text-align: left;
  text-wrap: nowrap;
`;

export const StyledSubtitle = styled.h1`
  font-family: Futura Extra Black Condensed;
  font-size: 22px;
  text-align: center;

  @media (${desktopMedia}) {
    font-size: 32px;
    text-align: left;
  }
`;

export const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (${desktopMedia}) {
    display: grid;
    grid-template-columns: 315px auto;
    grid-gap: 20px;
  }
`;

export const StyledLeftWrapper = styled.div`

`;

export const StyledRightWrapper = styled.div`
  padding-top: 20px;

  @media (${desktopMedia}) {
    padding-top: 0;
  }
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledCancelButton = styled(Button)`
  font-family: Futura Extra Black Condensed;
  font-size: 18px;
  background-color: #f3f3f3;
  width: 48%;
  border-radius: 50px;
  box-shadow: 4px 8px 24px 0 rgba(36, 107, 253, 0.25);
  padding: 15px 0;

  @media (${desktopMedia}) {
    width: 200px;
  }
`;

export const StyledContinueButton = styled(Button)`
  font-family: Futura Extra Black Condensed;
  font-size: 18px;
  background-color: #e31337;
  width: 48%;
  border-radius: 50px;
  box-shadow: 4px 8px 24px 0 rgba(36, 107, 253, 0.25);
  padding: 15px 0;
  transition: background-color 0.3s;

  :disabled {
    background-color: #d1d5db;
    color: white;
    cursor: not-allowed;
  }

  @media (${desktopMedia}) {
    width: 200px;
  }
`;

export const StyledDoctorCardWrapper = styled.div`
  background-color: white;
  border-radius: 28px;
  padding: 20px 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 10px;

  @media (${desktopMedia}) {
    border-radius: 48px;
    padding: 20px 25px 40px;
    flex-direction: column;
    gap: 5px;
  }
`;

export const StyledDoctorCardContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px;

  @media (${desktopMedia}) {
    flex-direction: column;
  }
`;

export const StyledRatingWrapper = styled.div`
  margin: 15px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  text-align: center;

  @media (${desktopMedia}) {
    margin: 20px auto;
    width: 90%;
    text-align: left;
    gap: 10px;
  }
`;

export const StyledRatingText = styled.div`
  font-family: Futura Extra Black Condensed;
  font-size: 20px;
`;

export const StyledFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-color: white;
  border-radius: 28px;
  padding: 24px;

  @media (${desktopMedia}) {
    border-radius: 48px;
    padding: 40px;
  }
`;

export const StyledFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledFieldLabel = styled.label`
  font-family: Futura Extra Black Condensed;
  font-size: 20px;
`;

export const StyledFieldInput = styled.textarea`
  background-color: #fafafa;
  border: none;
  resize: none;
  border-radius: 17px;
  font-family: "Apercu Pro";
  font-size: 14px;
  color: black;
  padding: 12px 17px;
`;