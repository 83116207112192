import {
  StyledButtonsWrapper,
  StyledCancelButton,
  StyledContinueButton,
  StyledDoctorCardContentWrapper,
  StyledDoctorCardWrapper,
  StyledFieldInput,
  StyledFieldLabel,
  StyledFieldsWrapper,
  StyledFieldWrapper,
  StyledLeftWrapper,
  StyledMainWrapper,
  StyledRatingText,
  StyledRatingWrapper,
  StyledRightWrapper,
  StyledSubtitle,
  StyledTitle,
  StyledTopPanel,
  StyledWrapper
} from './AppointmentReviewModal.styled';
import { Modal } from '../Modal/Modal';
import React, { useEffect, useState } from 'react';
import { Content } from '../../Content/Content';
import { TranslationTypesEnum } from '../../../lib/types/translation-types-enum';
import {
  StyledFavoriteWrapper,
  StyledImageWrapper,
  StyledSpecialization,
  StyledSpecializationName,
  StyledTitleText,
  StyledTitleWrapper
} from '../../UI/BookAnAppointmentDoctorCard/BookAnAppointmentDoctorCard.styled';
import Image from 'next/image';
import {
  useLeaveAppointmentReviewMutation,
  usePendingReviewsQuery,
  useSkipAppointmentReviewMutation
} from '../../../services/appointments/common';
import { SPECIALIZATIONS } from '@vaza-eu/shoelessly';
import { useRouter } from '../../../lib/hooks/use-router';
import { StarRating } from '../../UI/StarRating/StarRating';

type BookAnAppointmentModalProps = {}

export const AppointmentReviewModal = ({}: BookAnAppointmentModalProps) => {
  const { locale } = useRouter();
  const { data, refetch } = usePendingReviewsQuery();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');
  const [whatUserLiked, setWhatUserLiked] = useState('');
  const [whatWouldUserImprove, setWhatWouldUserImprove] = useState('');

  const {
    mutate: skipReview,
    isLoading: isSkipReviewLoading
  } = useSkipAppointmentReviewMutation();
  const {
    mutateAsync: submitReview,
    isLoading: isSubmitReviewLoading
  } = useLeaveAppointmentReviewMutation();

  useEffect(() => {
    setIsModalOpen(!!data);
  }, [data]);

  if (!data) return null;

  const handleContinue = async () => {
    try {
      await submitReview({
        consultationId: data.consultationId,
        whatWouldUserImprove,
        whatUserLiked,
        review,
        rating
      });
      await refetch();
      setIsModalOpen(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSkip = () => {
    skipReview({ consultationId: data.consultationId });
    setIsModalOpen(false);
  };

  const specializations = data.specialist.specializations.map((id) => SPECIALIZATIONS.find((s) => s.id === id)![locale]).join(
    ', ');

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => undefined}
      paperStyle={{
        overflow: 'auto',
      }}
    >
      <StyledWrapper>
        <StyledTopPanel>
          <StyledTitle>
            <Content id={'appointmentReviewModal.title'} type={TranslationTypesEnum.Main}/>
          </StyledTitle>
          <StyledSubtitle>
            <Content id={'appointmentReviewModal.subtitle'} type={TranslationTypesEnum.Main}/> {data.specialist.title}
            <span
              style={{ textTransform: 'uppercase' }}>{data.specialist.firstName} {data.specialist.lastName}</span> {data.specialist.credentials}?
          </StyledSubtitle>
        </StyledTopPanel>
        <StyledMainWrapper>
          <StyledLeftWrapper>
            <StyledDoctorCardWrapper>
              <StyledImageWrapper>
                <Image src={data.specialist.photoUrl || '/images/placeholder/profile-photo.png'} alt={''} fill/>
              </StyledImageWrapper>
              <StyledDoctorCardContentWrapper>
                <StyledTitleWrapper>
                  <StyledTitleText>
                    {data.specialist.title} <span
                    style={{ textTransform: 'uppercase' }}>{data.specialist.firstName} {data.specialist.lastName}</span> {data.specialist.credentials}
                  </StyledTitleText>
                  <StyledFavoriteWrapper>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.7097 0.582164C12.2355 0.582164 12.7605 0.656331 13.2596 0.823831C16.3355 1.82383 17.4438 5.19883 16.518 8.14883C15.993 9.65633 15.1347 11.0322 14.0105 12.1563C12.4013 13.7147 10.6355 15.098 8.73465 16.2897L8.52632 16.4155L8.30965 16.2813C6.40215 15.098 4.62632 13.7147 3.00215 12.148C1.88548 11.0238 1.02632 9.65633 0.492983 8.14883C-0.448684 5.19883 0.659649 1.82383 3.76882 0.806331C4.01048 0.722998 4.25965 0.664664 4.50965 0.632164H4.60965C4.84382 0.597998 5.07632 0.582164 5.30965 0.582164H5.40132C5.92632 0.597998 6.43465 0.689664 6.92715 0.857164H6.97632C7.00965 0.872998 7.03465 0.890498 7.05132 0.906331C7.23548 0.965498 7.40965 1.03216 7.57632 1.12383L7.89298 1.2655C7.9695 1.30631 8.05539 1.36866 8.12961 1.42256C8.17665 1.4567 8.219 1.48745 8.25132 1.50716C8.26492 1.51519 8.27874 1.52326 8.29268 1.53139C8.36413 1.5731 8.43856 1.61655 8.50132 1.66466C9.42715 0.957164 10.5513 0.573831 11.7097 0.582164ZM13.9263 6.58216C14.268 6.573 14.5597 6.29883 14.5847 5.948V5.84883C14.6096 4.68133 13.9022 3.62383 12.8263 3.2155C12.4846 3.098 12.1096 3.28216 11.9846 3.63216C11.868 3.98216 12.0513 4.3655 12.4013 4.48966C12.9355 4.68966 13.293 5.2155 13.293 5.798V5.82383C13.2772 6.01466 13.3347 6.19883 13.4513 6.3405C13.568 6.48216 13.743 6.56466 13.9263 6.58216Z"
                        fill="#E31337"
                      />
                    </svg>
                  </StyledFavoriteWrapper>
                </StyledTitleWrapper>
                <StyledSpecialization>
                  <Content id={'appointmentReviewModal.doctorCard.specialization'}/>{' '}
                  <StyledSpecializationName>
                    {specializations}
                  </StyledSpecializationName>
                </StyledSpecialization>
              </StyledDoctorCardContentWrapper>
            </StyledDoctorCardWrapper>
            <StyledRatingWrapper>
              <StyledRatingText>
                <Content id={'appointmentReviewModal.rating'} type={TranslationTypesEnum.Main}/>
              </StyledRatingText>
              <StarRating rating={rating} onRatingChange={setRating}/>
            </StyledRatingWrapper>
          </StyledLeftWrapper>
          <StyledRightWrapper>
            <StyledFieldsWrapper>
              <StyledFieldWrapper>
                <StyledFieldLabel>
                  <Content id={'appointmentReviewModal.review'} type={TranslationTypesEnum.Main}/>
                </StyledFieldLabel>
                <StyledFieldInput
                  rows={3}
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                />
              </StyledFieldWrapper>
              <StyledFieldWrapper>
                <StyledFieldLabel>
                  <Content id={'appointmentReviewModal.whatUserLiked'} type={TranslationTypesEnum.Main}/>
                </StyledFieldLabel>
                <StyledFieldInput
                  rows={3}
                  value={whatUserLiked}
                  onChange={(e) => setWhatUserLiked(e.target.value)}
                />
              </StyledFieldWrapper>
              <StyledFieldWrapper>
                <StyledFieldLabel>
                  <Content id={'appointmentReviewModal.whatWouldUserImprove'} type={TranslationTypesEnum.Main}/>
                </StyledFieldLabel>
                <StyledFieldInput
                  rows={3}
                  value={whatWouldUserImprove}
                  onChange={(e) => setWhatWouldUserImprove(e.target.value)}
                />
              </StyledFieldWrapper>
            </StyledFieldsWrapper>
          </StyledRightWrapper>
        </StyledMainWrapper>
        <StyledButtonsWrapper>
          <StyledCancelButton onClick={handleSkip} isLoading={isSkipReviewLoading}>
            <Content id={'appointmentReviewModal.skip'} type={TranslationTypesEnum.Main}/>
          </StyledCancelButton>
          <StyledContinueButton
            onClick={handleContinue}
            disabled={!rating || !review}
            isLoading={isSubmitReviewLoading}
          >
            <Content id={'appointmentReviewModal.submit'} type={TranslationTypesEnum.Main}/>
          </StyledContinueButton>
        </StyledButtonsWrapper>
      </StyledWrapper>
    </Modal>
  );
};